<template>
  <div class="app-web-site">
    <div class="bg-container">
      <div class="aw-header">
        <img src="@/assets/image/hm-logo.png" alt="" />
        <div class="aw-h-left">
          <span>嗨喵主持人工具</span>
          <span>让活动更精彩</span>
        </div>
        <span style="margin-left: auto">
          <a target="_blank" href="http://www.hudongmiao.com">嗨喵首页</a>
        </span>
        <!-- <span>视频教程</span> -->
        <div @click="showAppQrcode = !showAppQrcode" class="aw-h-download">
          <span>立即下载</span>
          <div v-show="showAppQrcode" class="popup">
            <img src="@/assets/image/app-qrcode.png" alt="" />
          </div>
        </div>
      </div>
      <div class="aw-content">
        <div class="aw-c-left">
          <span>让活动更<span>精彩</span></span>
          <span class="aw-cl-true"> <img src="@/assets/image/true.png" alt="" />10秒创建档期</span>
          <span class="aw-cl-true">
            <img src="@/assets/image/true.png" alt="" />重新定义婚礼主持圈</span
          >
          <span class="aw-cl-true">
            <img src="@/assets/image/true.png" alt="" />团队管理更便捷</span
          >
          <div @click="showAppQrcode2 = !showAppQrcode2" class="aw-cl-download">
            <span>立即下载</span>
            <img src="@/assets/image/download.png" alt="" />
            <div v-show="showAppQrcode2" class="popup">
              <img src="@/assets/image/app-qrcode.png" alt="" />
            </div>
          </div>
        </div>
        <div class="aw-c-right">
          <img src="@/assets/image/two-phone.png" alt="" />
        </div>
      </div>
    </div>
    <div
      :style="i_ % 2 == 0 && 'background-color: rgba(250,249,248,1)'"
      :key="i_"
      v-for="(i, i_) in list"
      class="aw-schedule"
    >
      <span class="title">{{ i.title }}</span>
      <span class="desc">{{ i.desc }}</span>
      <img :src="i.img" alt="" />
    </div>
    <div class="aw-host-hot">
      <span class="title">巅峰热门主持人</span>
      <span class="desc">活跃主持人、主持团队</span>
      <div class="aw-hh-container">
        <div v-for="(i, i_) in userlist" :key="i_" class="userlist">
          <img :src="i.user_headimg" alt="" />
          <div>
            <span>{{ i.user_qm_name }}</span>
            <span>￥{{ i.total_price }}</span>
          </div>
        </div>
        <div v-for="(i, i_) in teamlist" :key="i_" class="teamlist">
          <img :src="i.team_icon" alt="" />
          <span>{{ i.team_name }}</span>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import schedule from "@/assets/image/schedule.png";
import hostCircle from "@/assets/image/host-circle.png";
import team from "@/assets/image/team.png";
import self from "@/assets/image/self.png";
import myFooter from "@/components/myFooter.vue";
import { getShouYeInfo } from "@/api";
export default {
  components: {
    myFooter,
  },
  data: () => ({
    teamlist: [],
    userlist: [],
    showAppQrcode: false,
    showAppQrcode2: false,
    list: [
      {
        title: "档期管理",
        desc: "档期云备份，安全永不丢失",
        img: schedule,
      },
      {
        title: "嗨喵主持圈",
        desc: "专属嗨喵婚礼人的社交圈",
        img: hostCircle,
      },
      {
        title: "专属定制的个人主页",
        desc: "档期、作品查看，一目了然",
        img: self,
      },
      {
        title: "更加智能的团队管理系统",
        desc: "一键查询成员档期、分享成员资料",
        img: team,
      },
    ],
  }),
  methods: {
    to: (_) => window.open(_, "_blank"),
  },
  created() {},
  async mounted() {
    const { data } = await getShouYeInfo();
    this.teamlist = data.teamlist;
    this.userlist = data.userlist;
    console.log(this.teamlist);
  },
};
</script>

<style lang="less" scoped>
.app-web-site {
  .bg-container {
    width: 100%;
    height: 860px;
    background-image: url("../assets/image/app-bg.png");
    background-size: 100% 100%;
  }
  .aw-header {
    height: 96px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 0 266.67px;
    display: flex;
    align-items: center;
    img {
      width: 53.33px;
      height: 53.33px;
    }

    .aw-h-left {
      display: flex;
      flex-direction: column;
      margin-left: 10.67px;
      span:first-child {
        font-size: 21.33px;
        font-weight: bold;
      }
      span:last-child {
        color: rgba(0, 0, 0, 0.4);
        font-size: 16px;
      }
    }

    > span {
      color: #333;
      padding: 16px 32px;
      font-size: 18.67px;
      a {
        color: #333;
      }
    }
    .aw-h-download {
      background: linear-gradient(111deg, #ff5173, #fe0339 100%);
      padding: 16.6px 32px;
      border-radius: 5.33px;
      color: white;
      margin-left: 52px;
      font-size: 18.67px;
      position: relative;
      .popup {
        position: absolute;
        width: 200px;
        height: 200px;
        bottom: -20px;
        transform: translate(-50%, 100%);
        left: 50%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .aw-content {
    padding: 109.33px 357.94px 0 426.67px;
    display: flex;
    justify-content: space-around;

    .aw-c-left {
      display: flex;
      flex-direction: column;

      > span:first-child {
        font-size: 64px;
        margin-bottom: 54.67px;
        span {
          color: rgba(255, 81, 115, 1);
        }
      }
      img {
        width: 26.67px;
        height: 24px;
      }

      .aw-cl-true {
        margin-bottom: 30.67px;
        font-size: 32px;
        // font-weight: bold;
        display: flex;
        align-items: center;
        img {
          margin-right: 13.33px;
        }
      }

      .aw-cl-download {
        position: relative;
        .popup {
          position: absolute;
          width: 200px;
          height: 200px;
          bottom: -20px;
          transform: translate(-50%, 100%);
          left: 50%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      > div {
        width: 312px;
        height: 85.33px;
        background: #167bed;
        border-radius: 74.67px;
        color: white;
        display: flex;
        align-items: center;
        font-size: 32px;
        span {
          margin-left: 53.33px;
        }
        img {
          margin-left: auto;
          margin-right: 13.33px;
          width: 58.67px;
          height: 58.67px;
        }
      }
    }

    .aw-c-right {
      img {
        width: 462.67px;
        height: 650.67px;
      }
    }
  }

  .aw-schedule {
    padding: 80px 0 41.33px 0;
    height: 960px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 53.33px;
      font-weight: bold;
      margin-bottom: 21.33px;
    }
    .desc {
      font-size: 42.67px;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 25.33px;
    }
    img {
      width: 1066.67px;
      height: 658.67px;
    }
  }

  .aw-host-hot {
    padding: 80px 0 41.33px 0;
    height: 960px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 53.33px;
      font-weight: bold;
      margin-bottom: 21.33px;
    }
    .desc {
      font-size: 42.67px;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 25.33px;
    }

    .aw-hh-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0 321.33px;
      justify-content: space-between;
      > div {
        width: 0;
        flex: 0 1 calc(20% - 37.33px);
        img {
          width: 225.33px;
          height: 225.33px;
          border-radius: 5px;
          background-repeat: no-repeat;
          object-fit: cover;
        }
        div {
          display: flex;
          font-size: 18.67px;
          span:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span:last-child {
            margin-left: auto;
            color: rgba(255, 51, 87, 1);
          }
        }
      }
      .teamlist {
        margin-top: 32px;
      }
    }
  }
}
</style>
